import React from "react"
//import { Link } from "gatsby"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"
import { useLocalization } from "gatsby-theme-i18n"
import { useLocation } from "@reach/router"
import { StaticImage } from "gatsby-plugin-image"
import "./assets/styles/_index.scss"
import GGFXImage from "../GGFX/GGFXImage"
const { Site_Vars, OFF_PLAN } = require("../../common/site/config")

const PropertyCard = props => {
  const { t } = useTranslation()
  const { locale } = useLocalization()
  let details_path = ""
  const location = useLocation()
  if (props.data.department == "commercial") {
    if (props.data.search_type == "sales") {
      details_path = "/commercial-properties-for-sale"
    } else {
      details_path = "/commercial-properties-for-rent"
    }
  } else {
    if (props.data.search_type == "sales") {
      details_path = "/properties-for-sale"
    } else {
      details_path = "/properties-for-rent"
    }
  }

  if (props.style === "dubai" && props.propertyTypeVal == OFF_PLAN) {
    details_path = "/off-plan-properties-for-sale"
  } else if (props.propertyTypeVal == "new_developments") {
    details_path = "/new-home-for-sale"
  }

  var imagename = "property.images.results"
  let propid = ""
  if (props?.data?.crm_id) propid = encodeURIComponent(props.data.crm_id?.trim().toLowerCase())
  else if (props?.data?.strapi_id) propid = props.data.strapi_id
  else if (props?.data?.objectID) propid = props.data.objectID

  let propCount = props.data?.title?.slice(0, 1)
  let title =
    isNaN(propCount) &&
    props.data?.title?.slice(0, 1).toUpperCase() + props.data?.title?.slice(1)
  title ||= props?.data?.title

  const notIn = ["0", "No"]

  if(locale == "ar" && props?.data?.propid_lang){
    propid = props?.data?.propid_lang?.["ar"] ? props?.data?.propid_lang?.["ar"] : propid
  }

  let price_quaifier = props.data?.price_qualifier;
  if(locale == "ar" && price_quaifier){
    price_quaifier = props?.data?.price_qualifier_lang?.["ar"] ? props?.data?.price_qualifier_lang?.["ar"] : price_quaifier
  }
  let display_address = typeof props?.data?.display_address === 'object' ? props?.data?.display_address?.en : props?.data?.display_address
  if(locale == "ar" && display_address){
    display_address = props?.data?.display_address_lang?.["ar"] ? props?.data?.display_address_lang?.["ar"] : display_address
  }
  if(locale == "ar" && title){
    title = props?.data?.title_lang?.["ar"] ? props?.data?.title_lang?.["ar"] : title
  }
  propid = props?.data?.crm_id?.trim().toLowerCase();
  return (
    <div className="property-card-wrapper">
      <div className="property-card-img-zoom">
        {props.data.status && (
          <div className="status-tag">{props.data.status}</div>
        )}
        <Link to={details_path + "/" + props.data.slug.replace(/-ar/g, "") + "/" + propid + "/"}>
          {props.tag == "no-result" &&
            props.data.images?.strapi_json_value.length > 0 && (
              <GGFXImage
              ImageSrc={props.data.images?.strapi_json_value[0]}
              altText={""}
              imagetransforms={props.data?.ggfx_results}
              renderer="srcSet"
              imagename={imagename}
              strapiID={props.data?.strapi_id}
              className="img-fluid"
              />
            )}

          {props.tag != "no-result" && props?.data?.images?.length > 0 && (
            <img
              fetchpriority={props.myindexval == 0 ? "low" : "low"}
              loading={props.myindexval < 6 ? "eager" : "lazy"}
              src={props.propertyTypeVal === OFF_PLAN ? props.data?.images[0]["640x404"] : props.data?.images[0]["416x300"]}
              alt="banner"
              className="img-fluid"
            />
          )}
          {props.tag != "no-result" && props?.data?.images?.length == 0 && (
            <StaticImage
              src="../../images/no-image.png"
              layout="fullWidth"
              placeholder="blurred"
              formats={["webp"]}
              alt="banner"
              quality="90"
              className="img-fluid"
            />
          )}
        </Link>
      </div>
      <div className="property-card-text-wrapper">
        {props.propertyTypeVal !== OFF_PLAN ? (
          <div className="price-details">
            {!notIn.includes(price_quaifier) &&
              props.data.search_type !== "lettings"
              ? price_quaifier
              : ""}{" "}
            {Site_Vars.default_currency}
            {props.data?.price?.toLocaleString()}{" "}
            {!notIn.includes(price_quaifier) &&
              props.data.search_type === "lettings"
              ? price_quaifier
              : ""}
          </div>
        ) : (
          <div className="price-details">
            {props.data.price_qualifier} {Site_Vars.default_currency}
            {props.data?.price?.toLocaleString()}{" "}
            {props.data?.max_price
              ? ` - ${Site_Vars.default_currency
              }${props.data?.max_price?.toLocaleString()}`
              : ""}
          </div>
        )}
        <p className="display-address">
          <Link to={details_path + "/" + props.data.slug.replace(/-ar/g, "") + "/" + propid + "/"}>
          {display_address}
          </Link>
        </p>
        <div className="property-title">{title}</div>
        <ul className="property-info list-inline d-none align-items-center">
          {props.data?.bedroom ? (
            <li className="icon-bk bedroom d-flex">
              <span className="icon icon-bed"></span>
              <span className="icon-text">{props.data?.bedroom} </span>
            </li>
          ) : null}
          {props.data?.bathroom ? (
            <li className="icon-bk bathroom d-flex">
              <span className="icon icon-bath"></span>
              <span className="icon-text">{props.data?.bathroom} </span>
            </li>
          ) : null}
          {props.data?.floorarea_max ? (
            <>
              <li className="icon-bk sqft d-flex">
                <span className="icon icon-sqft"></span>
                <span className="icon-text">
                  {props.data.floorarea_max?.toLocaleString()}{" "}
                  {props.data.floorarea_type === "squareFeet" ? ((locale == "ar") ? "قدم مربع" : "sq.ft") : ""}
                </span>
              </li>
            </>
          ) : null}
        </ul>
      </div>
    </div>
  )
}

export default PropertyCard
